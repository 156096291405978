.project-heading {
  font-size: 56px;
  font-weight: 400;
}

.project-card:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.startup-projects-main {
  display: flex;
}

.startup-projects-main > * {
  flex: 1;
  margin-bottom: 30px;
}

.starup-project-image > img {
  max-width: 100%;
  height: auto;
}

.project-card-head {
  color: rgb(36, 41, 46);
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin: 0px;
}

.startup-project-text {
  display: flex;
  justify-content: space-between;
}

.project-card-description {
  color: rgb(88, 96, 105);
}
.saaya-health-div,
.nextu-div {
  cursor: pointer;
}
.grid-cont{
  flex-direction: row;
}
@media (max-width: 768px) {
  .starup-project-image {
    display: none;
  }
  .project-subtitle{
    font-size: 16px;
    text-align: center;
  }
  .grid-cont{
    flex-direction: column;
  }
  
}
