.certificate-card-footer span.project-tag {
    background: #521945;
    color: #f5f2f4;
    vertical-align: middle;
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    font-size: .75rem;
    height: 2em;
    justify-content: center;
    white-space: nowrap;
    line-height: 1.5;
    margin: 0 .5rem .5rem 0;
    padding: 0 .75em;
    transition: 0.20s ease-in;
}
